import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import { json } from '@remix-run/node';
import {
  isRouteErrorResponse,
  Link,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useNavigate,
  useRouteError,
} from '@remix-run/react';
import { useEffect } from 'react';
import { Logo } from './assets/Logo';
import { SplitGrid } from './components/split-grid';
import { Button } from './components/ui/button';
import { Toaster } from './components/ui/toaster';
import { Toast, toast } from './components/ui/use-toast';
import { Routes } from './constants/routes';

import { LinksFunction } from '@remix-run/node';
import styles from './brokkr.css?url';
import { withAugmentedContext } from './services/auth/Guards';

declare global {
  interface Window {
    ENV: string;
  }
}

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: styles }];

export const loader = withAugmentedContext(async ({ request, context }) => {
  const cookie = request.headers.get('Cookie');
  const session = await context.sessionManager.getSession(cookie);
  const toast = session.get('toast') as Toast;
  const environment = context.env.HH_ENV;

  return json(
    {
      toast,
      environment,
    },
    {
      headers: {
        'Set-Cookie': await context.sessionManager.commitSession(session),
      },
    },
  );
});

function App() {
  const data = useLoaderData<typeof loader>();

  useEffect(() => {
    if (data.toast) {
      toast(data.toast as Toast);
    }
  }, [data.toast]);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hs-scripts.com/46942358.js';
    script.async = true;
    script.defer = true;
    script.id = 'hs-script-loader';
    document.body.appendChild(script);

    return () => {
      const existingScript = document.getElementById(script.id);
      if (existingScript && document.body.contains(existingScript)) {
        document.body.removeChild(existingScript);
      }
    };
  }, []);

  return (
    <html lang="en" className="h-full">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width,initial-scale=1" />
        <script
          defer
          data-domain="brokkr.hydrahost.com"
          src="https://plausible.io/js/script.js"
        />
        <Meta />
        <Links />
      </head>

      <body className="h-full antialiased">
        <Outlet />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENV = ${JSON.stringify(data.environment)}`,
          }}
        />
        <ScrollRestoration />
        <Toaster />
        <Scripts />
      </body>
    </html>
  );
}

export function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  let errorTitle = 'Unexpected Error';
  let errorMessage = 'An unexpected error occurred. Please try again later.';
  let isCookieError = false;

  if (isRouteErrorResponse(error)) {
    errorTitle = `${error.status} ${error.statusText}`;
    errorMessage =
      error.data || 'An error occurred while processing your request.';
    isCookieError = error.status === 401;
  } else if (error instanceof Error) {
    errorTitle = 'Application Error';
    errorMessage = error.message;
    isCookieError = error.message?.toLowerCase().includes('cookie');
  }

  captureRemixErrorBoundaryError(error);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <html>
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <SplitGrid>
          <div className="mx-[15%] w-full">
            <h2 className={'scroll-m-20 text-3xl tracking-tight'}>
              {errorTitle}
            </h2>

            <p className="text-sm">{errorMessage}</p>
            <p className="mt-2 text-sm text-gray-500">
              If you're stuck or having issues, try clearing your cookies.
            </p>

            <div className="mt-4 space-x-4">
              <Button
                onClick={handleGoBack}
                className="text-md inline-flex items-center gap-2 rounded-sm bg-[#5A32FE] px-6 py-3 font-semibold"
              >
                Go Back
              </Button>

              <Button
                asChild
                className="text-md inline-flex items-center gap-2 rounded-sm bg-red-600 px-6 py-3 font-semibold"
              >
                <Link to={Routes.Logout}>Clear Cookies</Link>
              </Button>
            </div>
          </div>

          <Logo className="w-auto h-40" />
        </SplitGrid>
        <Scripts />
      </body>
    </html>
  );
}

export default withSentry(App);
